import React from 'react';
import { Button, Container, Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import {
  Link
} from "react-router-dom";
import Layout from '../../Layout';
import { useSwitcher } from '../../../components/useSwitcher';
import FractionsDatatable from '../../../components/Datatables/Fractions_Datatable';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    opacity: '.75'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    border: "none"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperWraper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent'
  },
  title: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pos: {
    marginBottom: 12,
  },
  example: {
    display: 'flex',
    alignItems: 'stretch'
  }
}));

export default function Fractions() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (    
    <Layout>
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <Link to="/">
                <Button
                  variant="outlined"
                  className={classes.menuButton}
                  startIcon={<KeyboardReturnIcon />}
                >
                  {
                    !switched ? 'назад' : 'nazad'
                  }
                </Button>          
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.title} variant="h4" component="h2">
                {
                  !switched ? 'разломци' : 'razlomci'
                }
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {/*Empty block */}
            </Grid>
          </Grid>
        </Toolbar>
        {/* Category Content */}
        <div className={classes.root}>
          <Container maxWidth="xl">
            <Paper elevation={0} className={matches ? classes.paperWraper : null}>
              <Grid justifyContent="center" container spacing={matches ? 3 : 0}>
                <Grid item xs={12} md={9}>
                  <Paper elevation={0} className={classes.paper}>
                    <FractionsDatatable />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </div>
    </Layout>
  )
}