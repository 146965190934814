import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import data from '../../assets/data/measurement.json';
import { useSwitcher } from '../../useSwitcher';
import { MathComponent } from 'mathjax-react';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: 650,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  firstRow: {
    paddingRight: 0,
    width: '300px',
    maxWidth: '300px'
  },
  secondRow: {
    width: '425px'
  }
});

export default function MeasurementDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data && data.measurement[0].example.map((item) => (
            <StyledTableRow key={item.ID}>
              <StyledTableCell className={classes.firstRow}>
                {
                  item.symbol ?
                  <MathComponent style={{textAlign: "left"}} tex={String.raw`n^${item.symbol}`} /> : <MathComponent style={{textAlign: "left"}} tex={String.raw`n`} />
                }
              </StyledTableCell>
              <StyledTableCell className={classes.secondRow}>
              {!switched ? item.Српски : item.Srpski}
              </StyledTableCell>
              <StyledTableCell>
                {item.English}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
                <strong>{!switched ? Object.keys(data.measurement[10].metricki_duzina[0]).slice(0, 1) : Object.keys(data.measurement[10].metricki_duzina[0]).slice(1, 2)}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Метричка јединица мере за дужину" : "Metrička jedinica mere za dužinu"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>{Object.keys(data.measurement[10].metricki_duzina[0]).slice(4, 5)}</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data && data.measurement[10].metricki_duzina.map((item) => (
            <StyledTableRow key={item["Metric units of length"]}>
              <StyledTableCell component="th" scope="row">
                {item["Ознака / Symbol"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Метричка јединица мере за дужину"] : item["Metrička jedinica mere za dužinu"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Metric units of length"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? Object.keys(data.measurement[8].metricki_povrsina[0]).slice(0, 1) : Object.keys(data.measurement[8].metricki_povrsina[0]).slice(1, 2)}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? Object.keys(data.measurement[8].metricki_povrsina[0]).slice(2, 3) : Object.keys(data.measurement[8].metricki_povrsina[0]).slice(3, 4)}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>{Object.keys(data.measurement[8].metricki_povrsina[0]).slice(4, 5)}</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data && data.measurement[8].metricki_povrsina.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row">
                <MathComponent style={{textAlign: "left"}} tex={String.raw`${item["Ознака / Symbol"]}^2`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Метричка јединица мере за површину"] : item["Metrička jedinica mere za površinu"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Metric units of area"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Метричка јединица мере за запремину" : "Metrička jedinica mere za zapreminu"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Metric units of capacity</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[7].metricki_zapremina.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row">
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}^3`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Метричка јединица мере за запремину"] : item["Metrička jedinica mere za zapreminu"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Metric units of capacity"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Метричка јединица мере за запремину течности" : "Metrička jedinica mere za zapreminu tečnosti"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Metric units of capacity</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[6].metricki_tecnost.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row">
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Метричка јединица мере за запремину течности"] : item["Metrička jedinica mere za zapreminu tečnosti"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Metric units of capacity"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Метричка јединица мере за масу" : "Metrička jedinica mere za masu"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Metric units of mass</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[9].metricki_masa.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row">
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Метричка јединица мере за масу"] : item["Metrička jedinica mere za masu"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Metric units of mass"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Јединица мере за време" : "Jedinica mere za vreme"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Units of time</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[5].metricki_vreme.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Јединица мере за време"] : item["Jedinica mere za vreme"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Units of time"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
      <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>{!switched ? "Амерички систем" : "Američki sistem"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Customary system</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Америчка јединица мере за дужину" : "Jedinica mere za vreme"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Customary units of length</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[4].americka_duzina.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Америчка јединица мере за дужину"] : item["Američka jedinica mere za dužinu"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Customary units of length"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Америчка јединица мере за површину" : "Američka jedinica mere za površinu"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Customary units of area</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[2].americka_povrsina.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row">
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}^2`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Америчка јединица мере за површину"] : item["Američka jedinica mere za površinu"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Customary units of area"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Америчка јединица мере за запремину" : "Američka jedinica mere za zapreminu"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Customary units of capacity</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[3].americka_zapremina.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row">
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}^3`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Америчка јединица мере за запремину"] : item["Američka jedinica mere za zapreminu"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Customary units of capacity"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}> 
              <strong>{!switched ? "Америчка јединица мере за запремину" : "Američka jedinica mere za zapreminu"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Customary units of capacity</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[1].americka_tecnost.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row">
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Америчка јединица мере за течност"] : item["Američka jedinica mere za tečnost"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Customary units of capacity"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>{!switched ? "Ознака / Symbol" : "Ознака / Symbol"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Америчка јединица мере за течност" : "Američka jedinica mere za tečnost"}</strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>Customary units of capacity</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.measurement[1].americka_tecnost.map((item) => (
            <StyledTableRow key={item["Ознака / Symbol"]}>
              <StyledTableCell component="th" scope="row">
                <MathComponent tex={String.raw`${item["Oznaka / Symbol"]}`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Америчка јединица мере за течност"] : item["Američka jedinica mere za tečnost"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["Customary units of capacity"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
    </TableContainer>
  )
}