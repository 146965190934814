import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import data from '../../assets/data/digit.json';
import { useSwitcher } from '../../useSwitcher';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: 650,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  firstRow: {
    width: "70px",
    maxWidth: "70px"
  },
  secondRow: {
    width: "590px",
    maxWidth: "590px"
  }
});

export default function DigitDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
              <strong>{!switched ? "Пример" : "Primer"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>Example</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableHead>
          <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data.example.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>
                {item.num}
              </StyledTableCell>
              <StyledTableCell>
              {!switched ? item.Srpski_cir : item.Srpski}
              </StyledTableCell>
              <StyledTableCell>
                {item.English}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <strong>
                {!switched ? 'Српски' : 'Srpski'}
                </strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>
                English
              </strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
          <TableHead>
            <StyledTableRow>
                <StyledTableCell component="th" scope="row" className={classes.firstRow}>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <strong>
                  {!switched ? (Object.keys(data.digits[0]).slice(2, 3)) : (Object.keys(data.digits[0]).slice(1, 2))}
                  </strong>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                <strong>
                  {(Object.keys(data.digits[0]).slice(3, 4))}
                </strong>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                </StyledTableCell>
            </StyledTableRow>
          </TableHead>
        <TableBody>
        {
          data.digits.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item[" Месна вредност"] : item[" Mesna vrednost"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {item[" Place value"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
    </TableContainer>
  )
}