import React, { useContext, useState } from 'react';
import { AppBar, Button, Card, CardActions, CardContent, Container, FormControl, FormHelperText, IconButton, InputLabel, TextField, Toolbar, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import HomeIcon from '@material-ui/icons/Home';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Link, NavLink } from 'react-router-dom';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Box } from '@mui/system';
import axios from 'axios';
import { UserContext } from '../../hooks/useContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: 'calc(100% - 16px)',
    margin: '8px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    borderRadius: '25px',
    color: '#f4f4f4',
    padding: '3px 10px',
    backgroundColor: 'purple',
  },
  text: {
    flexGrow: 1,
    marginLeft: theme.spacing(2)
  },
  alignTitle: {
    display: 'flex'
  },
  container: {
    padding: '100px 0'
  }
}));

export default function SignInPage({ handleHomeClick, history }) {
  const classes = useStyles();
  const { setStatus } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [openTost, setOpenTost] = useState(false);
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    password: '',
    ConfirmPassword: '',
    showPassword: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(inputs.password !== inputs.ConfirmPassword) {
      setOpenTost(true);
      setError("Sifra se ne podudara");
      return;
    }
    try {
      const config = {
        headers: {
          "Content-type": "application/json"
        }
      };

      const { data } = await axios.post('https://math-server.matematickirecnik.rs/users/create', inputs, config);
      localStorage.setItem('admin_info', JSON.stringify(data));
      const storageToObj = JSON.parse(localStorage.getItem("admin_info"));
      if(storageToObj._id !== data._id) return;
      setStatus(storageToObj._id === data._id);
      setOpenTost(false);
      setInputs({
        email: '',
        password: '',
        showPassword: false,
      });

      history.push("/admin");
      setOpenTost(false);
      setInputs({
        name: '',
        email: '',
        password: '',
        ConfirmPassword: '',
        showPassword: false,
      });

    } catch (error) {
      setError(error ? `${error}` : 'something is wrong');
      setOpenTost(true);
    }
  }; 

  const handleChange = (prop) => (event) => {
    setInputs({ ...inputs, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setInputs({ ...inputs, showPassword: !inputs.showPassword });
  };


  return (
    <React.Fragment>
        <AppBar className={classes.root} position="static" color="inherit" >
        <Container maxWidth="md">
          <Toolbar>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <Paper elevation={0} className={classes.paper}>
                  <Grid container spacing={3}>
                    <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      <a href="https://atpc.education" target="_blank" rel="noreferrer">
                        <Button className={classes.title}>
                          НОВО
                        </Button>
                      </a>
                    </Grid>
                    <Grid item xs={9}>
                      <Box sx={{ textAlign: 'left' }} variant="caption" className={classes.text}>
                        Посетите наш сајт припрема тестова за америчке колеџе
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper elevation={0} className={classes.paper}>
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                  <FacebookIcon />
                  </IconButton>
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <LinkedInIcon />
                  </IconButton>
                  <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                      <InstagramIcon />
                    </IconButton>
                  </a>
                  <NavLink to="/">
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">           
                      <HomeIcon />           
                    </IconButton>            
                  </NavLink>
                </Paper>
              </Grid>
            </Grid> 
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="sm" className={classes.container}> 
        <Card className={classes.root}>
          <CardContent>
            <Paper elevation={0} className={classes.paper}>
              <Typography variant="h5" component="h2" gutterBottom>
                Креирај нов налог
              </Typography>
              <Typography variant="caption" component="p" color="textSecondary">
                Креирајте налог за математички речник
              </Typography>
            </Paper>
            <form onSubmit={handleSubmit} className={classes.textFields} noValidate autoComplete="off">
              <Paper elevation={0} className={classes.paper}>
                <FormControl htmlFor="name" fullWidth variant="outlined">
                  <TextField
                    name='name'
                    id="name"
                    label="Име"
                    type="text"
                    autoComplete="current-name"
                    variant="outlined"
                    value={inputs.name}
                    onChange={handleChange('name')}
                  />     
                  <FormHelperText id="my-email-text">никад нећемо делити ваше име.</FormHelperText>        
                </FormControl>      
              </Paper>             
              <Paper elevation={0} className={classes.paper}>
                <FormControl htmlFor="email" fullWidth variant="outlined">
                  <TextField
                    name='email'
                    id="email"
                    label="Имејл Адреса"
                    type="email"
                    autoComplete="current-email"
                    variant="outlined"
                    value={inputs.email}
                    onChange={handleChange('email')}
                  />     
                  <FormHelperText id="my-email-text">никад нећемо делити ваш имејл.</FormHelperText>        
                </FormControl>      
              </Paper>             
              <Paper elevation={0} className={classes.paper}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="password">Шифра</InputLabel>
                    <OutlinedInput
                    name='password'
                    id="password"
                    type={inputs.showPassword ? 'text' : 'password'}
                    value={inputs.password}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {inputs.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }
                  labelWidth={70}
                  />
                  <FormHelperText id="my-password-text">никад нећемо делити вашу шифру.</FormHelperText>
                </FormControl>            
              </Paper>
              <Paper elevation={0} className={classes.paper}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="confirm-password">Потврди Шифру</InputLabel>
                    <OutlinedInput
                    name='confirm-password'
                    id="confirm-password"
                    type={inputs.showPassword ? 'text' : 'password'}
                    value={inputs.ConfirmPassword}
                    onChange={handleChange('ConfirmPassword')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {inputs.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }
                  labelWidth={120}
                  />
                </FormControl>            
              </Paper>
              <Paper elevation={0} className={classes.paper}>
                  <Button type="submit" color="primary" variant="contained" fullWidth>Креирај</Button>
              </Paper>
            </form>
          </CardContent>
          <CardActions>
            <Link to="/login">
              <Button style={{textTransform: 'lowercase'}} size="small">Већ имаш налог?</Button>
            </Link>
          </CardActions>
        </Card>
      </Container>
    </React.Fragment>
  )
}

// Jy7-wHC-mDF-8Wa