import React from 'react';
import { Button, Container, Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import {
  Link
} from "react-router-dom";
import GreekDatatable from '../../../components/Datatables/Greek_Datatable';
import Layout from '../../Layout';
import { useSwitcher } from '../../../components/useSwitcher';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    opacity: '.75'
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    border: "none"
  },
  title: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
    },
  },
}));

export default function Greek() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Layout>
        <Toolbar>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <Link to="/">
                  <Button
                    variant="outlined"
                    color=""
                    className={classes.menuButton}
                    startIcon={<KeyboardReturnIcon />}
                  >
                  {
                    !switched ? 'назад' : 'nazad'
                  }
                  </Button>          
                </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.title}  variant="h4" component="h2">
              {
                !switched ? 'грчки алфабет' : 'grčki alfabet'
              }
              </Typography>
            </Grid>
            <Grid item xs={3}>
            {/*Empty block */}
            </Grid>
          </Grid>
        </Toolbar>
        <div className={classes.root}>
          <Container maxWidth={matches ? "xl" : "md"}>
            <Grid container spacing={matches ? 3 : 0}>
            {
              matches ? <Grid item xs={2}></Grid> : null
            }
              <Grid item xs={12} sm={8}>
                <Paper elevation={0} className={classes.paper}>
                  <GreekDatatable />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
    </Layout>
  )
}