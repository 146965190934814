import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import data from '../../assets/data/power-roots.json';
import { useSwitcher } from '../../useSwitcher';
import { MathComponent } from 'mathjax-react';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: 650,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  firstRow: {
    width: '280px',
    maxWidth: '280px'
  },
  secondRow: {
    width: '280px',
    maxWidth: '280px'
  }
});

export default function PowerRootsDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}>
              <strong>{!switched ? "Степеновање | Exponents" : "Stepenovanje | Exponents"}</strong>
              </StyledTableCell>
              <StyledTableCell className={classes.secondRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data && data.power.map((item) => (
            <StyledTableRow key={item.English}>
              <StyledTableCell>
              {item.exponent !== "" ? <MathComponent tex={String.raw`${item.formula}^${item.exponent}`} /> : <MathComponent tex={String.raw`${item.formula}`} />}
              </StyledTableCell>
              <StyledTableCell>
              {!switched ? item.Српски : item.Srpski}
              </StyledTableCell>
              <StyledTableCell>
                {item.English}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}></StyledTableCell>
              <StyledTableCell className={classes.secondRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
      <TableBody>
      {
        data && data.power_example.map((item) => (
          <StyledTableRow key={item.English}>
            <StyledTableCell>
            {item.exponent !== "" ? <MathComponent tex={String.raw`${item.formula}^${item.exponent}`} /> : item.formula}
            </StyledTableCell>
            <StyledTableCell>
            {!switched ? item["Пример"]  : item["Primer"] }
            </StyledTableCell>
            <StyledTableCell>
              {item.Example}
            </StyledTableCell>
          </StyledTableRow>
        ))
      }
      </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
      <TableHead>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell className={classes.secondRow}>
            <strong>{!switched ? "Степеновање, потенцирање" : "Stepenovanje, potenciranje"}</strong>
            </StyledTableCell>
            <StyledTableCell><strong>Rising to the power</strong></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableHead>
          <StyledTableRow>     
              <StyledTableCell></StyledTableCell> 
              <StyledTableCell className={classes.secondRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data && data.power_potencional.map((item) => (
            <StyledTableRow key={item.English}>
              <StyledTableCell className={classes.firstRow}></StyledTableCell>    
              <StyledTableCell>
              {!switched ? item["Степеновање, потенцирање"] : item["Stepenovanje, potenciranje"]}
              </StyledTableCell>
              <StyledTableCell>
                {item["Rising to the power"]}
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}>
              <strong>{!switched ? "Кореновање | Roots" : "Korenovanje | Roots"}</strong>
              </StyledTableCell>
              <StyledTableCell className={classes.secondRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
      <TableBody>
      {
        data.roots_example.map((item) => (
          <StyledTableRow key={item.English}>
            <StyledTableCell>
              {item.formula !== "root" || item.root_value !== "" ? <MathComponent tex={String.raw`${item.root_value}`} /> : <MathComponent tex={String.raw`\sqrt{\quad}`} />}
            </StyledTableCell>
            <StyledTableCell>
            {!switched ? item.Српски  : item.Srpski }
            </StyledTableCell>
            <StyledTableCell>
              {item.English}
            </StyledTableCell>
          </StyledTableRow>
        ))
      }
      </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell className={classes.firstRow}></StyledTableCell>
            <StyledTableCell className={classes.secondRow}>
            <strong>{!switched ? "Пример" : "Primer"}</strong>
            </StyledTableCell> 
            <StyledTableCell><strong>Example</strong></StyledTableCell> 
          </StyledTableRow>
        </TableHead>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}>
              </StyledTableCell>
              <StyledTableCell className={classes.secondRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
      <TableBody>
      {
        data.roots.map((item) => (
          <StyledTableRow key={item.English}>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>
            {!switched ? item["Кореновање"]  : item["Korenovanje"] }
            </StyledTableCell>
            <StyledTableCell>
              {item["Taking the root"]}
            </StyledTableCell>
          </StyledTableRow>
        ))
      }
      </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell className={classes.firstRow}></StyledTableCell>
            <StyledTableCell className={classes.secondRow}><strong>{!switched ? "Корен са експонентима" : "Koren sa eksponentima"}</strong></StyledTableCell> 
            <StyledTableCell><strong>Root with exponents</strong></StyledTableCell> 
          </StyledTableRow>
        </TableHead>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}>
              </StyledTableCell>
              <StyledTableCell className={classes.secondRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
      <TableBody>
      {
        data.power_root_example.map((item) => (
          <StyledTableRow key={item["Example"]}>
            <StyledTableCell>
           {item.formula !== "" ? <MathComponent tex={String.raw`\sqrt[${item.power}]{${item.formula}}`} /> : null}
            </StyledTableCell>
            <StyledTableCell>
            {!switched ? item["Пример"]  : item["Primer"] }
            </StyledTableCell>
            <StyledTableCell>
              {item["Example"]}
            </StyledTableCell>
          </StyledTableRow>
        ))
      }
      </TableBody>
      </Table>
    </TableContainer>
  )
}