import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import data from '../../assets/data/fractions.json';
import { useSwitcher } from '../../useSwitcher';
import { MathComponent } from 'mathjax-react';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: 650,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  firstRow: {
    width: "140px",
    maxWidth: "140px",
  },
  secondRow: {
    width: "440px",
    maxWidth: "440px"
  }
});

export default function FractionsDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}></StyledTableCell>
              <StyledTableCell className={classes.secondRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data && data.example.map((item) => (
            <StyledTableRow key={item.English}>
              <StyledTableCell>
              <MathComponent tex={String.raw`${item.number}\frac{${item.counter}}{${item.denominator}}`} />
              </StyledTableCell>
              <StyledTableCell className={classes.secondRow}>
              {!switched ? item.Српски : item.Srpski}
              </StyledTableCell>
              <StyledTableCell>
                {item.English}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.secondRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            <strong>English</strong>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>        
        {
          data.fractions.map((item) => (
            <StyledTableRow key={item["fraction"]}>
              <StyledTableCell component="th" scope="row">
                {<MathComponent tex={String.raw`\frac{1}{${item.fraction}}`} />}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Српски"] : item["Srpski"]} 
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {item["English"]} 
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
    </TableContainer>
  )
}