import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import '../../App.css';

export default function Layout({ children }) {
  return (
    <div className="dictionary-app">
      <Header />
      { children }
      <Footer />
    </div>
  )
}