import React from 'react';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import { teal } from '@material-ui/core/colors';
import { useEffect } from 'react';
import { useState } from 'react';
import { IconButton } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent'
  },
  goTopBtn: {
    position: 'fixed',
    right: '5%',
    bottom: '5%',
    transform: 'translateY(0px)',
    transition: 'all .5s cubic-bezier(.77,0,.175,1)'
  },
  goTopBtnHide: {
    transform: 'translateY(150px)',
    transition: 'all .5s cubic-bezier(.77,0,.175,1)'
  },
  loginBtn: {
    '& .MuiSvgIcon-root': {
      color: '#a2fdec'
    }
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: teal[50]
    }
  },
});

export default function Footer() {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [isVisible, setIsVisible] = useState(false);
  const [removeGoTopBtn, setRemoveGoTopBtn] = useState(false);
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  function toggleVisibility() {
    const visibleBtn = window.scrollY;
    visibleBtn > 300 ? setIsVisible(prev => prev = true) : setIsVisible(prev => prev = false);
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    const originURL = "/";
    const currentURL = window.location.pathname;
    currentURL === originURL ? setRemoveGoTopBtn(prev => !prev) :
    setRemoveGoTopBtn(false);
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <footer className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={11}>
          <Paper elevation={0} className={classes.paper}>
            Math Dictionary &copy; 2021 - {getCurrentYear()}
            <Link to="/login" underline="none" className={classes.navLink} >
              <IconButton className={classes.loginBtn}>
                <MoreHorizIcon />          
              </IconButton>
            </Link>
          </Paper>
        </Grid>
      </Grid>
      {
        !removeGoTopBtn && (matches && 
        <ThemeProvider theme={theme}>
          <Fab onClick={() => scrollToTop()} className={ isVisible ? classes.goTopBtn : classes.goTopBtnHide } color="primary" aria-label="add">
            <NavigationIcon />
          </Fab>
        </ThemeProvider>)
      }
    </footer>
  );
}