import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
  Router,
  Route,
  Switch
} from "react-router-dom";
import { createBrowserHistory } from "history";
import LogInPage from './views/Login/LogInPage';
import SignInPage from './views/Login/SignInPage';
import Admin from './views/AdminDashboard/Admin/Admin';
import Page404 from './views/Page404';
// import UserProfile from './views/UserProfiles/User';
import { SwitchProvider } from './components/useSwitcher';
import Digit from './views/Categories/Digit';
import Greek from './views/Categories/Greek';
import Cardinals from './views/Categories/Cardinals';
import Polygons from './views/Categories/Polygons';
import Measurement from './views/Categories/Measurement';
import Figures from './views/Categories/Figures';
import Ordinals from './views/Categories/Oridnals';
import Prefiks from './views/Categories/Prefiks';
import LargeNums from './views/Categories/Large_Nums';
import Fractions from './views/Categories/Fractions';
import PowerRoots from './views/Categories/Power-Roots';
import Trigonometric from './views/Categories/Trigonometric';
import { UserContext } from './hooks/useContext';
import { SwitchVideoProvider } from './components/useVideoSwitcher';

const histo = createBrowserHistory();

const MathApp = () => {
  const [status, setStatus] = useState(false);

  return (
    <Router history={histo} basename="/">
      <SwitchProvider>
        <SwitchVideoProvider>
          <UserContext.Provider value={{status, setStatus}}>
            <Switch>
              <Route exact path="/" component={App} />
              <Route path="/digit" component={Digit} />
              <Route path="/greek" component={Greek} />
              <Route path="/cardinals" component={Cardinals} />
              <Route path="/measurement" component={Measurement} />
              <Route path="/polygons" component={Polygons} />
              <Route path="/figures" component={Figures} />
              <Route path="/ordinal-num" component={Ordinals} />
              <Route path="/prefixes" component={Prefiks} />
              <Route path="/large-nums" component={LargeNums} />
              <Route path="/fraction" component={Fractions} />
              <Route path="/power-roots" component={PowerRoots} />
              <Route path="/trigonometry" component={Trigonometric} />
              <Route path="/admin" component={Admin} />
              <Route path="/login" component={LogInPage} />
              <Route path="/signin" component={SignInPage} />
              <Route component={Page404} />
            </Switch>
          </UserContext.Provider>
        </SwitchVideoProvider>
      </SwitchProvider>
    </Router>
  )
}

ReactDOM.render(<MathApp />, document.getElementById("root"));