import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    visibility: 'visible'
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

export default function DictionarySnackbars({ messageInfo, open, handleClose, handleExited }) {
  const classes = useStyles();
  return (
    <div>
      <Snackbar
        className={classes.root}
        key={messageInfo ? messageInfo : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        transitionProps={handleExited}
        message={messageInfo ? messageInfo : undefined}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}