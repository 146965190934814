import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoginIcon from '@mui/icons-material/Login';
import video_icon from '../assets/icons/video_icon.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Button } from '@material-ui/core';
import LogedInNav from '../Loged_In_Nav';
import SwitchLetter from '../Switch_Letter';
import { useSwitcher } from '../useSwitcher';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      boxShadow: 'none',
      backgroundColor: 'transparent!important'
    }
  },
  grow: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      display: 'block',
    }
  },
  nav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  navBtn: {
    color: '#f4f4f4',
    fontSize: '14px',
    textTransform: 'capitalize',
    border: '2px solid #f4f4f4',
    borderRadius: '20px',
    padding: '3px 15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',      
    },
    '&:hover': {
      boxShadow: '0 0 30px #a2fdec',
    }
  },
  toolbar: {
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  app_title: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
      fontSize: 16,
      textAlign: 'center',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  loginButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  video_icon: {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    '& > .MuiIconButton-label img': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }
  },
  buttonLogin: {
    display: 'none'
  },
  navLinkEng: {
    '& .MuiIconButton-label': {
      justifyContent: 'flex-start'
    }
  }
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function Header() {
  const classes = useStyles();
  const { switched } = useSwitcher();

  return (
    <header className={classes.root}>
      <AppBar position="static">        
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.app_title} variant="h4" component="h1">
            Serbian - English Mathematics Dictionary by {!switched ? "Миленa Шовић" : "Milena Šović"}           
          </Typography>
          <div className={classes.grow} />
          <div className={classes.nav} >
          <SwitchLetter />
          <LightTooltip title={!switched ? "Видео Водич" : "Video Vodič"}>
            <Link href="https://www.youtube.com/watch?v=74CYzDMTvjc" target="_blank" underline="none" className={classes.navLink} >
              <IconButton>
                <img height="50%" width="50%" src={video_icon} alt="Видео Водич" />           
              </IconButton>
            </Link>
          </LightTooltip>
          <LightTooltip title="Video Guide">
            <Link href="https://www.youtube.com/watch?v=Q84AqhdbPF4" target="_blank" underline="none" className={classes.navLinkEng} >
              <IconButton>
                <img height="50%" width="50%" src={video_icon} alt="Video Guide" />           
              </IconButton>
            </Link>
          </LightTooltip>
          <Link href="https://atpc.education/" target="_blank" underline="none" className={classes.navLink} >
            <Button color="inherit" className={classes.navBtn}>
              ATPC Education
            </Button>     
          </Link>
          <div className={classes.buttonLogin}>
            <LightTooltip exact title="ценовник">
              <NavLink to="/payment" className={classes.navLink} >
                <Button color="inherit" className={classes.navBtn}>
                  <ShoppingCartIcon />
                </Button>     
              </NavLink>
            </LightTooltip>
          </div>        
          <div className={classes.buttonLogin}>
            <LightTooltip exact title="Пријавите се">
              <NavLink to="/login" className={classes.navLink} >
                <Button color="inherit" className={classes.navBtn}>
                  <LoginIcon />
                </Button>     
              </NavLink>
            </LightTooltip>
          </div>
          <LogedInNav />
          </div>
        </Toolbar>        
      </AppBar>
    </header>
  );
}