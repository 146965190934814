import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import data from '../../assets/data/large_nums.json';
import { useSwitcher } from '../../useSwitcher';
import { MathComponent } from 'mathjax-react';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: 650,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  small: {
    fontSize: '11px'
  }
});

export default function LargeNumsDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>Short scale
              <p className={classes.small}>(US, English Canada, modern British, Australia, and
                Eastern Europe)</p></strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>Long scale
              <p className={classes.small}>(French Canada, older British, Western & Central Europe)</p></strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data && data.large_nums.map((item) => (
            <StyledTableRow key={item.English}>
              <StyledTableCell>
              {!switched ? item.Српски : item.Srpski}
              </StyledTableCell>
              <StyledTableCell>
              {item.English}
              </StyledTableCell>
              <StyledTableCell>
              {item.short === "Not used" ? "Not used" : <MathComponent tex={String.raw`10^ {${item.short}}`} />}
              </StyledTableCell>
              <StyledTableCell>
                {<MathComponent tex={String.raw`10^ {${item.long}}`} />}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>
  )
}