import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MathComponent } from 'mathjax-react';
import { useSwitcher } from '../../useSwitcher';
import data from '../../assets/data/greek.json';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  math: {
    '& > *': {
      width: '10%!important'
    }
  }
});

export default function GreekDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  const columns = Object.keys(data.greek[0]).slice(2, 5);
  const columns_lat = Object.keys(data.greek[0]).slice(5, 8);
  const headings = !switched ? columns : columns_lat;
  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
          {
            headings.map((heading) =>
              <StyledTableCell key={heading.length}>
                <strong>{heading}</strong>
              </StyledTableCell>
            )
          }          
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.greek.map((row) => (
            <StyledTableRow hover key={row.id}>
              <StyledTableCell className={classes.math} component="th" scope="row">
                <MathComponent tex={String.raw`${row.Symbols}`} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {!switched ? row.Српски : row.Srpski}
              </StyledTableCell>
              <StyledTableCell>
                {row.English}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
