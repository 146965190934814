import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import ImageModal from '../../Image_Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import FloatingActionButtons from '../../Dashboard_Action_Buttons';
import EditRows from '../../Dashboard_Edit_Rows';
import axios from 'axios';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,
  },
  empty: {
    color: theme.palette.grey[300],
    fontSize: '24px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '20px'
  },
  onHover:{
    position: 'relative',
    width: "100%",
    "& .hidden-button": {
      '& > div': {
        visibility: 'hidden',
      }
    },
    "&:hover .hidden-button": {
      '& > div': {
        visibility: 'visible',
        justifyContent: 'flex-end',
        border: 'none'
      }
    }
  },
  hiddenBtnHeader: {
    minWidth: '200px'
  },
  editRow: {
    position: 'relative',
    zIndex: 1
  }
}));


export default function DashboardDatatableLatin({ data }) {
  const classes = useStyles();
  const columns = data[0] && Object.keys(data[0]);
  const [src, setSrc] = useState('');
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [rows, setRows] = useState({
    items: Array.from({ length: 20 }),
  });
  const [inputRows, setInputRows] = useState({
    Srpski: '',
    Engleski: ''
  });

  const fetchMore = () => {
    setRows({
      items: rows.items.concat(Array.from({ length: 20 }))
    });
  };

  const handleChangeRow = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const filedValue = event.target.value;
    const newInputRows = {...inputRows};
    newInputRows[fieldName] = filedValue;
    setInputRows(newInputRows);
  };

  const handleEditRow = (id, dataValue) => {
    setIsEdit(id);
    const inputValues = {
      Srpski: dataValue.Srpski,
      Engleski: dataValue.Engleski
    };
    setInputRows(inputValues);
  };

  const handleClose = () => {
    setOpen(false);
    setSrc('');
  };

  const handleSubmit = (e, id) => {
    e.preventDefault();
    const NewInputs = {
      Srpski: inputRows.Srpski,
      Engleski: inputRows.Engleski
    };
    axios.put(`https://math-server.matematickirecnik.rs/dictionary-latin/${id}`, NewInputs).then(() => window.location.reload(false));
  };

  return (
    <TableContainer id="scrollableDiv" className={classes.container} component={Paper}> 
      <InfiniteScroll
      dataLength={rows.items}
      next={fetchMore}
      hasMore={true}
      height={525}
      scrollableTarget="scrollableDiv"
    >   
      <Table stickyHeader className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
            {
              columns && columns.slice(1, 3).map((heading, index)=>
              <StyledTableCell align={index === 2 ? 'right' : 'left'} key={index}>
                {heading}
              </StyledTableCell>             
              )
            }
            <StyledTableCell className={classes.hiddenBtnHeader} align='right'></StyledTableCell>        
            </TableRow>
          </TableHead>        
          <TableBody>        
            {data.length === 0 ? data = [] : data && data.slice(0, rows.items.length).map((row) => (
              <StyledTableRow className={classes.onHover} hover key={row._id}>
                <StyledTableCell component="th" scope="row">
                {
                  isEdit === row._id ? <EditRows styleInput={classes.editRow} name="Srpski" rowId={row._id} dataValue={inputRows.Srpski} handleChangeRow={handleChangeRow}/> : row.Srpski
                }
                </StyledTableCell>
                <StyledTableCell>
                {
                  isEdit === row._id ? <EditRows styleInput={classes.editRow} name="Engleski" rowId={row._id} dataValue={inputRows.Engleski} handleChangeRow={handleChangeRow}/> : row.Engleski
                }
                </StyledTableCell>
                <TableCell align="right" className="hidden-button">
                  <FloatingActionButtons handleEditRow={handleEditRow} closeEdit={setIsEdit} handleSubmit={handleSubmit} rowId={row._id} dataRow={row} />
              </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
      </Table>
      {
        data.length === 0 ? <Typography className={classes.empty}>Rezultat nije pronađen</Typography> : null
      }
      <ImageModal handleClose={handleClose} open={open} src={src} />
      </InfiniteScroll>
    </TableContainer>
  )
}
