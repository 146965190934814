import React, { useState } from 'react';
import { withStyles, makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { LinearProgress, Typography } from '@material-ui/core';
import ImageModal from '../../Image_Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSwitcher } from '../../useSwitcher';
import { teal } from '@material-ui/core/colors';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
    },
  },
  body: {
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,
  },
  container: {
    height: 650,
    opacity: '.8'
  },
  empty: {
    color: theme.palette.grey[300],
    fontSize: '24px',
    fontWeight: '600',
    textAlign: 'center',
    padding: '20px'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  loadingProgress: {
    width: '80%',
    marginTop: '50px',
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: teal[900]
    }
  },
});

export default function DictionaryDatatable({ data, search, loading }) {
  const classes = useStyles();
  const columns = data[0] && Object.keys(data[0]);
  const { switched } = useSwitcher();
  const [src, setSrc] = useState('');
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [rows, setRows] = useState({
    items: Array.from({ length: 20 }),
  });

  const fetchMore = () => {
    setRows({
      items: rows.items.concat(Array.from({ length: 20 }))
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSrc('');
  };

  return (
    <TableContainer id="scrollableDiv" className={classes.container} component={Paper}> 
      <InfiniteScroll
      dataLength={rows.items}
      next={fetchMore}
      hasMore={true}
      height={650}
      scrollableTarget="scrollableDiv"
    >   
      <Table stickyHeader className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
            {
              columns && columns.slice(1, 3).map((heading, index)=>
              <StyledTableCell align={index === 2 ? 'right' : 'left'} key={index}>
                {heading}
              </StyledTableCell>             
              )
            }               
            </TableRow>
          </TableHead>        
          <TableBody>        
            {search === '' || data.length === 0 ? data = [] : data && data.slice(0, rows.items.length).map((row) => (
              <StyledTableRow hover key={row._id}>
                <StyledTableCell component="th" scope="row">
                  {!switched ? row.Српски : row.Srpski}
                </StyledTableCell>
                <StyledTableCell>
                  {!switched ? row.Енглески : row.Engleski}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
      </Table>
      {
        !loading ? 
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <LinearProgress className={classes.loadingProgress} color="primary" />
          </div>
        </ThemeProvider>
         : search === '' ? 
         <Typography className={classes.empty}>{!switched ? "Унесите реч у поље за унос" : "Unesite reč u polje za unos"}</Typography> : search !== "" && data.length === 0 && <Typography className={classes.empty}>{!switched ? "Резултат није пронађен" : "Rezultat nije pronađen"}
         </Typography>
        
      }
      <ImageModal handleClose={handleClose} title={title} open={open} src={src} />
      </InfiniteScroll>
    </TableContainer>
  )
}
