import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../../../components/Navbars/Navbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import logo from "../../../components/assets/icons/logo.svg";
import routes from "../../../routes";
import image from "../../../components/assets/img/sidebar-1.jpg";
import styles from "../../../components/assets/jss/material-dashboard-react/layouts/adminStyle";
import { UserContext } from '../../../hooks/useContext';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/cyrillic-edit" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  const { status } = useContext(UserContext)
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isLogedin, setIsLogedin] = React.useState(localStorage.getItem("admin_info") !== null)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  
  if(!status && !isLogedin) {
    return <Redirect to="/login" />
  }
  
  return (
    <div className={classes.wrapper}>
      <Sidebar        
        routes={routes}
        logoText={"ATPC Education"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="blue"
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        /> 
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
      </div>
    </div>
  );
    
}

