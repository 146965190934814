import React, { useContext, useState } from 'react';
import { AppBar, Button, Card, CardContent, Container, FormControl, FormHelperText, IconButton, InputLabel, TextField, Toolbar, Typography, Paper, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import HomeIcon from '@material-ui/icons/Home';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { NavLink, Redirect } from 'react-router-dom';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../hooks/useContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: 'calc(100% - 16px)',
    margin: '8px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    borderRadius: '5px',
    color: '#f4f4f4',
    padding: '3px 10px',
    backgroundColor: 'purple',
    "&:hover" : {
      backgroundColor: 'darkgray'
    }
  },
  text: {
    flexGrow: 1,
    marginLeft: theme.spacing(2)
  },
  container: {
    padding: '100px 0'
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export default function LogInPage() {
  const classes = useStyles();
  const history = useHistory();
  const { setStatus } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [openTost, setOpenTost] = useState(false);
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setInputs({ ...inputs, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setInputs({ ...inputs, showPassword: !inputs.showPassword });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(inputs.email === '' || inputs.password === '') {
      setOpenTost(true);
      setError("Попуните сва поља");
      return;
    }
    try {
      const config = {
        headers: {
          "Content-type": "application/json"
        }
      };

      const { data } = await axios.post('https://math-server.matematickirecnik.rs/users/login', inputs, config);
      
      localStorage.setItem('admin_info', JSON.stringify(data));
      const storageToObj = JSON.parse(localStorage.getItem("admin_info"));
      if(storageToObj._id !== data._id) return;
      setStatus(storageToObj._id === data._id);
      setOpenTost(false);
      setInputs({
        email: '',
        password: '',
        showPassword: false,
      });

      history.push("/admin");

    } catch (error) {
      setError(error ? `${error}` : 'something is wrong');
      setOpenTost(true);
      setStatus(false);
      history.push("/login");
    }
  }; 

  if(localStorage.getItem("admin_info") !== null) {
    return <Redirect to="/admin" />
  }

  return (
    <React.Fragment>
        <AppBar className={classes.root} position="static" color="inherit" >
        <Container maxWidth="md">
          <Toolbar>
            <Grid container spacing={3}>
              <Grid className={classes.logoContainer} item xs={12} sm={4}>
                <Paper elevation={0} className={classes.paper}>
                  <Grid container spacing={3}>
                    <Grid item xs={3} sm={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      <a href="https://atpc.education" target="_blank" rel="noreferrer">
                        <Button className={classes.title}>
                          <svg width="42" height="30" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.5 13.089L32.395 0L64 13.089L32.395 26.1781L0.5 13.089Z" fill="white"/>
                          <path d="M13.5469 33.1244L14.4167 22.4395L32.3939 29.6518L49.5012 22.4395L50.951 33.1244C39.6428 32.323 34.4236 37.3984 32.104 39.0011C27.2327 34.086 17.7029 33.0353 13.5469 33.1244Z" fill="white"/>
                          </svg>
                        </Button>
                      </a>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Paper elevation={0} className={classes.paper}>
                <Link href="https://www.facebook.com/" target="_blank">
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                  <FacebookIcon />
                  </IconButton>
                </Link>
                  <Link href="https://www.linkedin.com/" target="_blank">
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                      <LinkedInIcon />
                    </IconButton>
                  </Link>
                  <Link href="https://www.instagram.com/" target="_blank">
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                      <InstagramIcon />
                    </IconButton>
                  </Link>
                  <NavLink to="/">
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">           
                      <HomeIcon />           
                    </IconButton>            
                  </NavLink>
                </Paper>
              </Grid>
            </Grid> 
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="sm" className={classes.container}> 
        <Card className={classes.root}>
          <CardContent>
            <Paper elevation={0} className={classes.paper}>
              <Typography variant="h5" component="h2" gutterBottom>
                Пријавите се
              </Typography>
              <Typography variant="caption" component="p" color="textSecondary">
                Пријавите се у контролни панел математички речник
              </Typography>
              </Paper>
              <Paper elevation={0} className={classes.paper}>
              {openTost &&
                  <Collapse in={openTost}>
                    <Alert
                      severity="error"
                      action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenTost(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {error}
                  </Alert>
                </Collapse>
              }
              </Paper>
            <form action='/login' onSubmit={handleSubmit} className={classes.textFields} noValidate autoComplete="off">
              <Paper elevation={0} className={classes.paper}>
                <FormControl htmlFor="email" fullWidth variant="outlined">
                  <TextField
                    name='email'
                    id="email"
                    label="Имејл Адреса"
                    type="email"
                    autoComplete="current-email"
                    variant="outlined"
                    value={inputs.email}
                    onChange={handleChange('email')}
                  />     
                  <FormHelperText id="my-email-text">никад нећемо делити ваш имејл.</FormHelperText>        
                </FormControl>      
              </Paper>             
              <Paper elevation={0} className={classes.paper}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="password">Шифра</InputLabel>
                    <OutlinedInput
                    name='password'
                    id="password"
                    type={inputs.showPassword ? 'text' : 'password'}
                    value={inputs.password}
                    onChange={handleChange('password')}
                    label="Шифра"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {inputs.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }
                  labelWidth={70}
                  />
                  <FormHelperText id="my-password-text">Никад нећемо делити вашу шифру.</FormHelperText>
                </FormControl>            
              </Paper>
              <Paper elevation={0} className={classes.paper}>
                  <Button type="submit" color="primary" variant="contained" fullWidth>
                    Пријави се
                  </Button>
              </Paper>
            </form>
          </CardContent>
          <NavLink to="/signin">
            <Button style={{textTransform: 'lowercase'}} size="small">
              Креирај нови налог
            </Button>       
          </NavLink>
        </Card>
      </Container>
    </React.Fragment>
  )
}

// Jy7-wHC-mDF-8Wa