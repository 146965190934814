import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useSwitcher } from '../useSwitcher';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 0px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    opacity: '.8'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    padding: '5px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    },
    
  },
  iconButton: {
    padding: 10,
  },
}));


export default function SearchDatatable({ search, handleSearch, onClickClear, onClickSearch, switchedEdit }) {
  const classes = useStyles();
  const { switched } = useSwitcher();
  
  return (
      <Paper component="form" onSubmit={onClickSearch} className={classes.root}>
        <IconButton onClick={onClickSearch} className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          value={search}
          onChange={handleSearch}
          className={classes.input}
          placeholder={!switched || switchedEdit ? "Претражите речи на српском и енглеском" : "Pretražite reči na srpskom i engleskom"}
          inputProps={{ 'aria-label': 'Search words in Serbian or English' }}
        />
        {
          search && 
            <IconButton onClick={onClickClear} className={classes.iconButton} aria-label="search">
              <ClearIcon />
            </IconButton>
        }        
      </Paper>
  )
}