import React, { useCallback, useEffect, useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CategoryButtons from '../../components/Category_Buttons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CategoryDrawer from '../../components/Category_Drawer';
import { Link } from 'react-router-dom';
import DictionaryDatatable from '../../components/Datatables/Dictionary_Datatable';
import axios from 'axios';
import SearchDatatable from '../../components/Search_Datatable';
import { useSwitcher } from '../../components/useSwitcher';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent'
  },
  paperContainer: {
    padding: theme.spacing(2),
    borderRadius: '20px',
    border: '3px solid white',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      padding: theme.spacing(0),
    },
  },
  searchGrid: {
    [theme.breakpoints.down('md')]: {
      padding: '0!important'
    }
  },
  btns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
    width: '100%',
    gap: 10,
    backgroundColor: 'transparent'
  },
  btns_container: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function Dictionary(props) {
  const { dictionaryProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { switched } = useSwitcher();
  const [dictionary, setDictionary] = useState([]);
  const [dictionaryLatin, setDictionaryLatin] = useState([]);
  const [search, setSearch] = useState('');
  const [run, setRun] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    let matchSpace = e.target.value.match(/\s*/);
    if(matchSpace[0] === e.target.value) {
      setSearch(() => '');
      setRun(() => false);
      return;
    }else {
      setSearch(e.target.value);
    }
    if(e.target.value === 0 && search.length === 0){
      setDictionary([]);
      setDictionaryLatin([]);
    }
  };

  const hasWord = (word, toMatch) => {
    let wordSplitted = word.split(' ');
      if(wordSplitted.join(' ').includes(toMatch)) {
        return true;
      }
      return false;
  };

  function searchTable(rows) {
    return rows && rows.lenght !== 0 ? rows.filter((row) => ((row.Srpski && hasWord(row.Srpski.toLowerCase(), search.toLowerCase())) || (row.Српски && hasWord(row.Српски.toLowerCase(), search.toLowerCase()))) || (row.Engleski && hasWord(row.Engleski.toLowerCase(), search.toLowerCase())) || (row.Енглески && hasWord(row.Енглески.toLowerCase(), search.toLowerCase()))) : dictionary;
  }

  useEffect(()=> {
      setSearch('');
      if(isLoading) return;
      const getLatinData = async () => {
        const responseLatin = await axios.get('https://math-server.matematickirecnik.rs/dictionary-latin');
        setDictionaryLatin(responseLatin.data);
        setIsLoading(true);
      };
      getLatinData();
      const getData = async () => {
        if(isLoading) return;
        const response = await axios.get('https://math-server.matematickirecnik.rs/dictionary');
        setDictionary(response.data);
        setIsLoading(true);
      };
      getData();
  }, [switched, isLoading]);  
  
  function onClickClear(e) {
    e.preventDefault();
    setSearch(() => '');
    setRun(() => false);
  }

  function onClickSearch(e) {
    e.preventDefault();
    if(e.key === 'Enter' || search.length) {
      setRun(() => true);
    }
  }

  const handleBackspace = useCallback(
    () => {
      if(search.length) {
          setRun(() => false);
      }
    },
    [search]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleBackspace);
    return () => {
      document.removeEventListener('keydown', handleBackspace);
    };
  }, [handleBackspace]);

  useEffect(() => {
    getAnal();
  }, []);

  const getAnal = async () => {
    try {
      await axios.get("https://api.countapi.xyz/update/math-app/math-app22/?amount=1");
    } catch (error) {
      console.log(error);
    }
  }

  const getAnalCat = async () => {
    try {
      await axios.get("https://api.countapi.xyz/update/math-category/math-category22/?amount=1");
    } catch (error) {
      console.log(error);
    }
  }

  return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <CategoryDrawer categoryButtons={dictionaryProps.categoryButtonsProps} match={dictionaryProps.match} />       
          <Grid className={classes.btns_container} item xs>
            <Paper elevation={0} className={classes.btns}>  
            {
              matches && dictionaryProps.categoryButtonsProps.buttons.slice(0,6).map((row, index) => (
                <Link to={`${dictionaryProps.match.url}${row.name}`} key={row.id}>      
                  <CategoryButtons
                    onClickButton={getAnalCat}
                    key={index}
                    name={row.name}
                    icon={row.icon}
                    srb={!switched ? row.srb_name : row.srb_name_lat}
                    eng={row.eng_name}
                  />
                </Link> 
                ))
              }          
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.searchGrid}>
            <Paper elevation={0} className={classes.paperContainer}>
              <Paper elevation={0} className={classes.paper}>
                <SearchDatatable search={search} onClickSearch={onClickSearch} handleSearch={handleSearch} onClickClear={onClickClear} />
              </Paper>
              <Paper elevation={0} className={classes.paper}>
                <DictionaryDatatable loading={isLoading} search={search} data={run && (!switched ? searchTable(dictionary) : searchTable(dictionaryLatin))} />
              </Paper>
            </Paper>
          </Grid>
          <Grid className={classes.btns_container} item xs>
            <Paper elevation={0} className={classes.btns}>  
            {
              matches && dictionaryProps.categoryButtonsProps.buttons.slice(6,12).map((row, index) => (
              <Link to={`${dictionaryProps.match.url}${row.name}`} key={row.id}>
                <CategoryButtons
                  onClickButton={getAnalCat}
                  key={index}
                  name={row.name}
                  icon={row.icon}
                  srb={!switched ? row.srb_name : row.srb_name_lat}
                  eng={row.eng_name}
                />
              </Link> 
              ))
              }          
            </Paper>
            </Grid>
        </Grid>        
      </div>
  )
}