import React from 'react';
import { TextField } from '@material-ui/core';

export default function EditRows({ styleInput, name, rowId, dataValue, handleChangeRow, error }) {
  
  return (
    <React.Fragment>
      <TextField error={error !== null} className={styleInput} fullWidth required id={rowId} name={name} value={dataValue} onChange={handleChangeRow} helperText={error} />
    </React.Fragment>
  )
}
