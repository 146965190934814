import React, { useEffect, useState } from 'react';
import SearchDatatable from '../../../components/Search_Datatable/';
import axios from 'axios';
import DashboardDatatableLatin from '../../../components/Datatables/Dashboard_Datatable_Latin';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import InputsPostDbLat from '../../../components/Inputs_Post_DB_Lat';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent'
  },
  paperContainer: {
    padding: theme.spacing(2),
    borderRadius: '20px',
    border: '3px solid white',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      padding: theme.spacing(0),
    },
  },
}));


export default function EditLatin() {
  const classes = useStyles();
  const switchedEdit = false;
  const [dictionaryLatin, setDictionaryLatin] = useState([]);
  const [search, setSearch] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  useEffect(() => {
    getDataLatin();
  }, []);

  const getDataLatin = async () => {
    const response = await axios.get('https://math-server.matematickirecnik.rs/dictionary-latin');
    setDictionaryLatin(response.data);
  };

  function searchTableLatin(rows) {
    return rows && rows.lenght !== 0 ? rows.filter((row) => (row.Srpski && row.Srpski.toLowerCase().indexOf(search.toLowerCase()) > -1) || (row.Engleski && row.Engleski.toLowerCase().indexOf(search.toLowerCase()) > -1)) : dictionaryLatin;
  }

  function onClickClear(e) {
    e.preventDefault();
    setSearch(() => '');
  }
  return (      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <InputsPostDbLat
            search={search}
            handleSearch={handleSearch}
            />
          </Paper>
        </Grid>      
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <SearchDatatable
              switchedEdit={switchedEdit}
              search={search}
              handleSearch={handleSearch}
              onClickClear={onClickClear} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <DashboardDatatableLatin
            search={search}
            data={searchTableLatin(dictionaryLatin)}
            />
          </Paper>
        </Grid>
      </Grid>
  )
}
