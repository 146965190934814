import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import { createTheme, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, ThemeProvider } from '@material-ui/core';
import Button from '../CustomButtons/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { purple } from '@material-ui/core/colors';
import axios from 'axios';
import DictionarySnackbars from '../SnackBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      '& button' : {
        width: '100%'
      }
    }
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1,
    padding: theme.spacing(0),
  },
  inputHide: {
    display: 'none'
  },
  formHelper: {
    wordBreak: 'break-word'
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: purple[500]
    }
  },
});

export default function InputsPostDb() {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [inputs, setInputs] = useState({
    Српски: '',
    Енглески: ''
  });

  const handleChange = (prev) => (event) => {
    setInputs({ ...inputs, [prev]: event.target.value });
  };
  
  function handleSubmit(e) {
    e.preventDefault();

    const NewInputs = {
      Српски: inputs.Српски,
      Енглески: inputs.Енглески
    };

    setProgress(0);
    const options = {
      onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent;
        let precent = Math.floor((loaded * 100) / total);
        setProgress((prev) => {
          if(prev === 100) {
            return 0;
          }
          return precent;
        }); 
      }
    };

    if(!inputs.Српски || !inputs.Енглески) {
      setError("попуните обавезна поља Српски и Енглески");
      return;
    }
    
    axios.post('https://math-server.matematickirecnik.rs/dictionary', NewInputs, options).then(response => setSnackPack(response.data)).then(() => window.location.reload(false));

    setInputs({
      Српски: '',
      Енглески: ''
    });
  }

    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo(snackPack);
      setSnackPack([]);
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} onSubmit={handleSubmit} component="form" encType="multipart/form-data">
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12} sm={5}>
            <Paper elevation={0} className={classes.paper}>
              <FormControl fullWidth required className={`${classes.margin} ${classes.textField}`} variant="outlined">
                <InputLabel htmlFor="serbian">Српски</InputLabel>
                <OutlinedInput
                  required
                  id="serbian"
                  name="Српски"
                  type="text"
                  value={inputs.Српски}
                  onChange={handleChange('Српски')}
                  aria-describedby="Srpska verzija"
                  labelWidth={50}
                  error={error !== null}
                  />
                <FormHelperText id="my-email-text">{error}</FormHelperText>  
              </FormControl>
            </Paper>     
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper elevation={0} className={classes.paper}>
              <FormControl fullWidth required className={`${classes.margin} ${classes.textField}`} variant="outlined">
                <InputLabel htmlFor="english">Енглески</InputLabel>
                <OutlinedInput
                  required
                  id="english"
                  name="Енглески"
                  type="text"
                  value={inputs.Енглески}
                  onChange={handleChange('Енглески')}
                  aria-describedby="Engleska verzija"
                  labelWidth={70}
                  error={error !== null}
                />
                <FormHelperText id="my-email-text">{error}</FormHelperText>    
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Paper elevation={0} className={classes.paper}>
              <Button color="primary" type="submit" endIcon={<SendIcon />}>
                Убаци
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <div className={classes.root}>
              {
                progress === 0 ? null :
                <LinearProgress color="primary" variant="determinate" value={progress} />
              } 
              </div>
            </Paper>
          </Grid>
        </Grid>
        <DictionarySnackbars messageInfo={messageInfo} open={open} handleClose={handleClose} handleExited={handleExited} />
      </Paper>
    </ThemeProvider>
  )
}