import React, { useState, useContext, createContext, useEffect } from 'react';

const SwitchVideoContext = createContext();

export const useVideoSwitcher = () => {
  return useContext(SwitchVideoContext);
};

export const SwitchVideoProvider = ({ children }) => {
  const defaultValue = false;
  const [switchedVideo, setSwitchedVideo] = useState(() => {
    const storeVideoLink = window.localStorage.getItem("video-link");
    return storeVideoLink !== null ? JSON.parse(storeVideoLink) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem("video-link", JSON.stringify(switchedVideo));
  }, [switchedVideo]);

  const handleChangeSwitchVideo = () => {
    setSwitchedVideo((prev) => !prev);
  };

  const values = {
    switchedVideo,
    handleChangeSwitchVideo
  };

  return (
    <SwitchVideoContext.Provider value={values}>
      { children }
    </SwitchVideoContext.Provider>
  )

};