import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import data from '../../assets/data/trigonometric.json';
import { useSwitcher } from '../../useSwitcher';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: 650,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  firstRow: {
    width: '650px',
    maxWidth: '650px'
  }
});

export default function TrigonometricDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}>
              <strong>{!switched ? "Тригонометрија" : "Trigonometrija"}</strong>
              </StyledTableCell>
              <StyledTableCell><strong>Trigonometry</strong></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data.trigonometric.map((item) => (
            <StyledTableRow key={item["English"]}>
              <StyledTableCell>
              {!switched ? item["Српски"] : item["Srpski"]}
              </StyledTableCell>
              <StyledTableCell>
                {item["English"]}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
                <strong>
                {!switched ? 'Хиперболичке функције' : 'Hiperboličke funkcije'}
                </strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row"><strong>Hyperbolic functions</strong></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data.hyperbolic.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["хиперболичке функције"] : item["hiperboličke funkcije"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {item["hyperbolic functions"]}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
      <TableHead>
        <StyledTableRow>
            <StyledTableCell component="th" scope="row">
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
            <StyledTableCell component="th" scope="row" className={classes.firstRow}>
              <strong>
              {!switched ? 'Инверзне тригонометријске функције' : 'inverzne trigonometrijske funkcije'}
              </strong>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row"><strong>Inverse trigonometric functions / Inverse trig functions</strong></StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
      {
        data.inverse_trigonometric.map((item) => (
          <StyledTableRow key={item["inverse trigonometric functions / inverse trig functions"]}>
            <StyledTableCell component="th" scope="row">
              {!switched ? item["инверзне тригонометријске функције"] : item["inverzne trigonometrijske funkcije"]}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              {item["inverse trigonometric functions / inverse trig functions"]}
            </StyledTableCell>
          </StyledTableRow>
        ))
      }          
      </TableBody>
    </Table>
    </TableContainer>
  )
}