import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Icon, ListItemIcon, ListSubheader, SwipeableDrawer } from '@material-ui/core';
import PaletteIcon from '@material-ui/icons/Palette';
import {
  Link
} from "react-router-dom";
import { useSwitcher } from '../useSwitcher';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    position: 'relative',
    top: 0,
    backgroundColor: 'rgba(200,200,200,.2)',
    borderRadius: '0 15px 15px 0',
    padding: '0px 0px',
    margin: '0px 0 0 -20px',
    boxShadow: '0 0 30px #a2fdec',
    zIndex: 100
  },
  icons: {
    filter: 'invert(1)'
  },
  subheaderColor: {
    backgroundColor: theme.palette.background.paper
  }
}));

export default function CategoryDrawer({ categoryButtons, match}) {
  const { switched } = useSwitcher();
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListSubheader className={classes.subheaderColor}>
          <ListItem>
            <ListItemText primary={!switched ? "Категорије" : "Kategorije"} />
          </ListItem>
        </ListSubheader>
        {
          categoryButtons.buttons.map((row) => (
          <Link to={`${match.url}${row.name}`} key={row.id}>
            <ListItem button>
              <ListItemIcon>
                <Icon>
                  <img className={classes.icons} height="100%" width="100%" src={`${row.icon}`} alt={`${row.name}`} />
                </Icon>
              </ListItemIcon>
              <ListItemText primary={!switched ? row.srb_name : row.srb_name_lat} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.sectionMobile}>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{<PaletteIcon style={{color: '#f4f4f4', fontSize: '32px'}}/>}
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
