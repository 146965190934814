import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

export default function Page404() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '500px'}}>
      <Typography variant="h1" component="h1">404</Typography>
      <Typography>Страница није пронађена</Typography>
      <Typography>Изгледа да сте залутали, вратите се на почетну страницу</Typography>
      <br />
      <NavLink to="/">
        <Button variant="outlined">Назад на сигурно</Button>
      </NavLink>
    </div>
  )
}
