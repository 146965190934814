import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// core components
import styles from "../assets/jss/material-dashboard-react/components/tableStyle.js";
import { IconButton } from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles(styles);

export default function TableUsers(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;

  function deleteRow(id) {
    axios.delete(`https://math-server.matematickirecnik.rs/users/${id}`)
    .then(() => {
      window.location.reload(false);
    });
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                <TableCell>
                  {prop._id.slice(20, prop._id.length)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {prop.name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {prop.email}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {prop.password.slice(0, 10)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <IconButton onClick={() => deleteRow(prop._id)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

TableUsers.defaultProps = {
  tableHeaderColor: "gray"
};

TableUsers.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
