import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import data from '../../assets/data/figures.json';
import { useSwitcher } from '../../useSwitcher';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: 650,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  firstRow: {
    width: '610px'
  }
});

export default function FiguresDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  const dataFigures = !switched ? data.figures_cir : data.figures;
  const dataFigures_3d = !switched ? data.figures_cir_3d : data.figures_3d;
  console.log(dataFigures.map(item => item["Дводимензионалне фигуре"]));
  console.log(data.example);
  return (
    <TableContainer component={Paper}>
    <Table>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell className={classes.firstRow}>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data.example.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>
              {!switched ? item.Српски : item.Srpski}
              </StyledTableCell>
              <StyledTableCell>
                {item.English}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
                <strong>
                {!switched ? Object.keys(data.figures_cir[0]).slice(0, 1) : Object.keys(data.figures[0]).slice(0, 1)}
                </strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>
                {Object.keys(data.figures_cir[0]).slice(1, 2)}
              </strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          dataFigures.map((item) => (
            <StyledTableRow key={item["Дводимензионалне фигуре"] || item["Dvodimenzionalne figure"]}>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Дводимензионалне фигуре"] : item["Dvodimenzionalne figure"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {item["Two - dimensional figures"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell component="th" scope="row" className={classes.firstRow}>
                <strong>
                {!switched ? Object.keys(data.figures_cir_3d[0]).slice(0, 1) : Object.keys(data.figures_3d[0]).slice(0, 1)}
                </strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <strong>
                {Object.keys(data.figures_cir_3d[0]).slice(1, 2)}
              </strong>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          dataFigures_3d.map((item) => (
            <StyledTableRow key={item["Тродимензионална тела"] || item["Trodimenzionalna tela"]}>
              <StyledTableCell component="th" scope="row">
                {!switched ? item["Тродимензионална тела"] : item["Trodimenzionalna tela"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {item["Three - dimensional figures, solids"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              
              </StyledTableCell>
            </StyledTableRow>
          ))
        }          
        </TableBody>
      </Table>
    </TableContainer>
  )
}