import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import DictionarySnackbar from "../SnackBar";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2,
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  colorEditButton: {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "darkgreen"
    }
  }
}));



export default function FloatingActionButtons({ rowId, dataRow, handleEditRow, closeEdit, handleSubmit }) {
  const classes = useStyles();
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo(snackPack);
      setSnackPack([]);
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }

  // const handleClick = (message) => () => {
  //   setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };


  function saveRow(e, id) {
    handleSubmit(e, id);
    closeEdit(false);
  }

  function deleteRow(id) {
    axios.delete(`https://math-server.matematickirecnik.rs/dictionary/${id}`)
    .then(response => setSnackPack(response.data)).then(() => window.location.reload(false));
  }

  function deleteRowLatin(id) {
    axios.delete(`https://math-server.matematickirecnik.rs/dictionary-latin/${id}`);
  }

  return (
    <div className={classes.root}>
      <Fab size="small" onClick={() => handleEditRow(rowId, dataRow)} className={classes.colorEditButton} aria-label="edit">
        <EditIcon />
      </Fab>
      <Fab size="small" type="submit" onClick={(e) => saveRow(e, rowId)} color="primary" aria-label="add">
        <SaveIcon />
      </Fab>
      <Fab onClick={() => deleteRow(rowId) || deleteRowLatin(rowId)} size="small" color="secondary" aria-label="edit">
        <DeleteForeverIcon />
      </Fab>
      <DictionarySnackbar messageInfo={messageInfo} open={open} handleClose={handleClose} handleExited={handleExited} />
    </div>
  );
}
