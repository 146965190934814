import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles, ThemeProvider, createTheme } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import TableUsers from "../../../components/Table/TableUsers.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import { purple } from '@material-ui/core/colors';
import TableAnalitics from "../../../components/Table/TableAnalitics.js";

const styles = {
  
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  inputPadding: {
    paddingTop: '10px'
  }
};

const theme = createTheme({
  palette: {
    primary: purple,
  },
});

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [adminData, setAdminData] = useState([]);
  const [error, setError] = useState(null);
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setInputs({ ...inputs, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setInputs({ ...inputs, showPassword: !inputs.showPassword });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    if(!inputs.name || !inputs.email || !inputs.password) {
      setError("Попуните сва поља");
      return;
    }
    
    if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(inputs.email) === false) {
      setError("Imejl nije validan");
      return;
    }
    
    const AdminRegistred = {
      name: inputs.name,
      email: inputs.email,
      password: inputs.password
    };

    try {
      
      axios.post('https://math-server.matematickirecnik.rs/users/create', AdminRegistred).then(() => window.location.reload(false));
      setInputs({
        name: '',
        email: '',
        password: '',
        showPassword: false,
      });
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    getAdminData();
  }, []);

  const getAdminData = async () => {

    try {
      const response = await axios.get('https://math-server.matematickirecnik.rs/users');
    setAdminData(response.data);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Креирај Новог Корисника</h4>
            <p className={classes.cardCategoryWhite}>
              Креирај приступ кориснику за уређивање речника
            </p>
          </CardHeader>
          <CardBody> 
          <form onSubmit={handleSubmit} className={classes.textFields} noValidate autoComplete="off">
          <ThemeProvider theme={theme}>
          
            <Paper elevation={0} className={classes.paper + ' ' + classes.inputPadding}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  color="primary"
                  name='name'
                  id="outlined-name-input"
                  label="Име"
                  type="text"
                  autoComplete="current-name"
                  variant="outlined"
                  value={inputs.name}
                  onChange={handleChange('name')}
                  error={error !== null}
                />     
                <FormHelperText id="my-email-text">{error}</FormHelperText>        
              </FormControl>      
            </Paper>
            <Paper elevation={0} className={classes.paper + ' ' + classes.inputPadding}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  name='email'
                  id="outlined-email-input"
                  label="Имејл Адреса"
                  type="email"
                  autoComplete="current-email"
                  variant="outlined"
                  value={inputs.email}
                  onChange={handleChange('email')}
                  error={error !== null}
                />     
                <FormHelperText id="my-email-text">{error}</FormHelperText>        
              </FormControl>      
            </Paper>             
            <Paper elevation={0} className={classes.paper + ' ' + classes.inputPadding}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="component-simple">Шифра</InputLabel>
                  <OutlinedInput
                  name='password'
                  id="outlined-adornment-password"
                  type={inputs.showPassword ? 'text' : 'password'}
                  value={inputs.password}
                  onChange={handleChange('password')}
                  label="Шифра"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {inputs.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
                />
                <FormHelperText id="my-password-text">{error}</FormHelperText>
              </FormControl>            
            </Paper>
            <Paper elevation={0} className={classes.paper + ' ' + classes.inputPadding}>
              <Button type="submit" color="primary" variant="contained" onClick={handleSubmit}>
                Додај новог корисника
              </Button>
            </Paper>
            </ThemeProvider>
          </form>
        </CardBody>
      </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>
              Табела Корисника
            </h4>
            <p className={classes.cardCategoryWhite}>
              Корисник који има приступ уређивању речника
            </p>
          </CardHeader>
          <CardBody>
            <TableUsers
              tableHeaderColor="primary"
              tableHead={["ИБ", "Име", "Имејл Адреса", "Шифра", ""]}
              tableData={adminData}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>
              Аналитика
            </h4>
            <p className={classes.cardCategoryWhite}>
              Мерење посета математичком речнику у сваком тренутку
            </p>
          </CardHeader>
          <CardBody>
            <TableAnalitics
              tableHeaderColor="primary"
              tableHead={["Број посета почетној страници", "Број посета категоријама"]}
            />
          </CardBody>
        </Card>
      </GridItem>
  </GridContainer>
 );
}