import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "../assets/jss/material-dashboard-react/components/tableStyle.js";
import axios from "axios";

const useStyles = makeStyles(styles);

export default function TableAnalitics(props) {
  const classes = useStyles();
  const { tableHead, tableHeaderColor } = props;
  const [analData, setAnalData] = useState();
  const [analCategoryData, setAnalCategoryData] = useState();

  useEffect(() => {
    getAnalAmount();
    getAnalAmountCategory();
  }, []);

  const getAnalAmount = async () => {
    try {
      const response = await axios.get("https://api.countapi.xyz/get/math-app/math-app22/");
      setAnalData(response.data.value);
    } catch (error) {
      console.log(error);
    }
  }

  const getAnalAmountCategory = async () => {
    try {
      const response = await axios.get("https://api.countapi.xyz/get/math-category/math-category22/");
      setAnalCategoryData(response.data.value);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          <TableRow>
            <TableCell>
              {analData}
            </TableCell>
            <TableCell>
              {analCategoryData}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

TableAnalitics.defaultProps = {
  tableHeaderColor: "gray"
};

TableAnalitics.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
