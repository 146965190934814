import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import data from '../../assets/data/prefiks.json';
import { useSwitcher } from '../../useSwitcher';
import { MathComponent } from 'mathjax-react';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: 650,
  },
  topHead: {
    display: 'flex',
    justifyContent: 'space-around'
  }
});

export default function PrefiksDatatable() {
  const classes = useStyles();
  const { switched } = useSwitcher();
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
              <strong>{!switched ? "Српски" : "Srpski"}</strong>
              </StyledTableCell>
              <StyledTableCell>
              <strong>English</strong>
              </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableHead>
          <StyledTableRow>
          <StyledTableCell></StyledTableCell>
            <StyledTableCell>
            <strong>
              {!switched ? "симбол / symbol" : "simbol / symbol"}
            </strong>
            </StyledTableCell>
            <StyledTableCell>
            <strong>
              {!switched ? "префикс" : "prefiks"}
            </strong>
            </StyledTableCell>
            <StyledTableCell>
              <strong>prefix</strong>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        {
          data && data.prefiks.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>
              {item.exponent === "1" ? "100" : item.exponent === "0" ? "1" : <MathComponent tex={String.raw`10^ {${item.exponent}}`} />}
              </StyledTableCell>
              <StyledTableCell>
              {item.symbol}
              </StyledTableCell>
              <StyledTableCell>
              {!switched ? item.Српски : item.Srpski}
              </StyledTableCell>
              <StyledTableCell>
                {item.English}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>
  )
}