import Edit from "../views/AdminDashboard/Edit";
import EditLatin from "../views/AdminDashboard/Edit_Lat";
import EditIcon from '@mui/icons-material/Edit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import UsersList from "../views/AdminDashboard/Users_List";

const dashboardRoutes = [
  {
    path: "/cyrillic-edit",
    name: "Уреди Речник(Ћир)",
    icon: EditIcon,
    component: Edit,
    layout: "/admin"
  },
  {
    path: "/latin-edit",
    name: "Uredi Rečnik(Lat)",
    icon: EditIcon,
    component: EditLatin,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Корисници & Аналитика",
    icon: PeopleAltIcon,
    component: UsersList,
    layout: "/admin"
  },
];

export default dashboardRoutes;